// Colors
$black: #000;
$blue: #477DCA;
$dark-gray: #444;
$medium-gray: #999;
$light-gray: #F7F7F7;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #E6EFC2;
$white: #FFF;
$highlight: #FFFF00;

// Font Colors
$font-color--base: $black;
$action-color: $black;
$action-color--alt: $black;
$action-color--contrast: $white;

// Background Colors
$viewport-background-color: $white;

:root {
  // Typography
  --font-family: system-ui, sans-serif;
  --font-family--heading: var(--font-family);

  --font-weight--thin: 100;
  --font-weight--extra-light: 200;
  --font-weight--light: 300;
  --font-weight--normal: 400;
  --font-weight--medium: 500;
  --font-weight--semi-bold: 600;
  --font-weight--bold: 700;
  --font-weight--extra-bold: 800;
  --font-weight--black: 900;

  // Line heights
  --line-height: 1.5;
  --line-height--heading: 1.2;

  // Other Sizes
  --border-radius: 0.1875rem;
  --spacing: 1.5rem;
  --spacing--small: 0.75rem;

  // Borders
  --border-width: 0.0625rem;
  --border: var(--border-width) solid currentColor;

  // Focus
  --focus-outline-width: 0.1875rem;
  --focus-outline-offset: 0.125rem;
  --focus-outline: var(--focus-outline-width) solid #{$action-color};

  // Animations
  --duration: 150ms;
  --timing: ease;
}
