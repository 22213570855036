$small-screen: (
  columns: 12,
  media: "screen and (max-width: 800px)",
);

$normal-screen: (
  columns: 12,
  media: "screen and (min-width: 800px)",
);

#container {
  @include grid-container;

  @include grid-media($small-screen) {
    @include grid-shift(1 of 12);
    @include grid-column(10 of 12);
  }

  @include grid-media($normal-screen) {
    @include grid-shift(3 of 12);
    @include grid-column(6 of 12);
  }

  text-align: left;

  header {
    margin-top: 1em;

    a#header-text {
      text-decoration: none;
      font-size: 3.015em;
      font-weight: var(--font-weight--medium);
    }
  }

  #menu {
    a {
      color: $medium-gray;
      margin: 0;
      padding: 0.25em;
      text-decoration: none;

      &:hover {
        color: $black;
        background-color: $highlight;
      }
    }
  }

  #content {
    h3 {
      font-size: 1.3em;

      a {
        text-decoration: none;
        padding: 0.25em;
      }
    }
  }

  .post {
    margin-top: 2em;
    margin-bottom: 1em;
    color: $black;
    border-bottom: 1px dashed $medium-gray;
    text-align: justify;

    &:last-child {
      margin-bottom: 1em;
    }

    a {
      text-decoration: underline;

      &:hover {
        background-color: $highlight;
        text-decoration: none;
      }
    }

    p {
      margin-top: 0.75em;
      margin-bottom: 0.75em;
    }

    .meta {
      margin: 1em 0;
      color: $medium-gray;
      text-align: left;

      a {
        margin: 0;
        color: $medium-gray;
        padding: 0.25em;

        &:hover {
          color: $black;
          background-color: $highlight;
          text-decoration: none;
        }
      }
    }
  }

  .pagination {
    @include clearfix;

    margin-bottom: 2em;

    .older {
      float: left;
    }

    .newer {
      float: right;
    }
  }

  #archives, #tags {
    margin-top: 2em;

    h4 {
      margin-bottom: 0.5em;
    }

    ul {
      margin-bottom: 0.5em;
    }

    a {
      padding: 0.25em;

      &:hover {
        background-color: $highlight;
        text-decoration: none;
      }
    }
    .indent {
      padding-left: 0.5em;
    }
  }

  #four-oh-four {
    margin-top: 2em;
    margin-bottom: 1em;

    a {
      text-decoration: underline;

      &:hover {
        background-color: $highlight;
        text-decoration: none;
      }
    }
  }
}

.highlight {
  padding: 0.2em;
  background-color: $light-gray;
  font-size: 95%;

  pre {
    font-family: $font-stack-consolas;
    margin: 0.5em;
    overflow: auto;
  }
}

p {
  code {
    padding: 0.2em;
    background-color: $light-gray;
    font-size: 95%;
  }
}

#disqus_thread {
  margin-top: -1em;
}

figure {
  margin: 1em 0;
}

blockquote {
  border-left: 2px solid $light-gray;
  color: lighten($font-color--base, 15);
  margin: 1.5em 0;
  padding-left: 0.75em;
}
